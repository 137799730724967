import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { SignUp } from 'domains/authorization/containers/sign-up';

const TITLE = 'Sign Up';

const SignUpPage: ApplicationPage = () => {
  return (
    <Page>
      <SignUp scrollTopOnStepChange />
    </Page>
  );
};

SignUpPage.getMeta = () => ({ title: TITLE });

SignUpPage.getMainLayoutProps = () => ({
  size: 'sm',
  centered: true,
  subheader: null,
});

export default SignUpPage;
